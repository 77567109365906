<template>
    <default-app-template>
        <PatientCharacteristics :actions="true" />
        <PatientAvailableTests />
        <PatientMeasurements />
    </default-app-template>
</template>

<script>
export default {
    name: 'Dashboard',
}
</script>
